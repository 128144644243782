.user-profile-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin: 20px auto;
  width: 30%;
  padding: 20px;
  text-align: center;
}

.edit-profile-button:active,
.edit-profile-button:focus {
  background-color: #ffffff;
  border-color: #025aa5;
  color: #000000;
}

.edit-profile-button:hover {
  background-color: #025aa5;
  color: #fff;
}

.card-img {
  border-radius: 50%;
  height: 280px;
  width: 280px;
}

.edit-camara-button-div {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.4);
}
.profile-field-div {
  width: 65%;
}
.profile-image-div {
  display: inline-flex;
  position: relative;
}
.profile-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
  border-radius: 50%;
}
.profile-image-letter {
  background: black;
  color: white;
  font-size: 70px;
  user-select: none;
}
.edit-image-icon {
  position: absolute;
  cursor: pointer;
  align-self: flex-end;
}

.profile-menu-items {
  border: 1px solid #000;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
  cursor: pointer;
}
.profile-menu-items:hover {
  background-color: #cac8c8;
}

.profile-input {
  border: 0;
  outline: 0;
  background: transparent;
  border-bottom: 2px solid rgb(255, 255, 255);
  color: white;
  font-weight: bold;
  font-size: large;
  display: flex;
  text-align: center;
  font-family: "Poppins";
  padding-bottom: 5px;
}

.edit-profile {
  border: 0;
  outline: 0;
  border-bottom: 1px solid gray;
  width: 65%;
  font-size: 16px;
}
.profile-main-div {
  display: flex;
}
.profile-image-main-div {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  background-color: rgb(182, 114, 12);
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-image-button {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
}

.edit-icon {
  position: absolute;
  color: white;
  top: 45%;
  left: 45%;
  cursor: pointer;
  font-size: 25px;
}
@media screen and (max-width: 425px) {
  .profile-field-div {
    width: 100%;
  }
  .profile-image-main-div {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: transparent;
  }
  .profile-image-button {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: -45px;
  }
  .profile-table tr {
    display: flex;
  }
  .profile-main-div {
    display: flex;
    flex-direction: column;
  }
  .edit-icon {
    top: 45%;
    left: 47%;
  }
  .card-img {
    height: 200px;
    width: 200px;
  }
}
