/* ProductDetails.css */

.product-details-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.product-image img {
  width: 50%;
  height: auto;
  border-radius: 8px;
}

.product-info {
  flex: 1;
  margin-left: 20px;
}

.product-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.product-price {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.product-other-details {
  font-size: 18px;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.product-other-details span {
  font-weight: 700;
}

.product-description {
  font-size: 16px;
  margin-bottom: 20px;
}

.add-to-cart-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.add-to-cart-button:hover {
  background-color: #0056b3;
}

.sales-details-text {
  margin-bottom: 5px;
}

.sales-details-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.order-details-container p {
  display: flex;
  flex-wrap: wrap;
}
