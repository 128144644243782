.dashboard_div {
  width: 80%;
  text-align: center;
  border-radius: 10px;
  background: linear-gradient(
    0deg,
    rgb(76 76 76 / 74%) 0%,
    rgb(114 118 129) 100%
  );
  color: white;
  padding: 0 !important;
}

.dashboard_div:hover,
.dashboard_div:focus,
.active > .dashboard_div {
  background-color: #000000;
  color: #fff;
}

.dashboard_last-synced {
  font-size: 10px;
  opacity: 0.5;
  color: white;
  pointer-events: all;
}
.lazySelect {
  width: 75%;
}

@media screen and (max-width: 1024px) {
  .dashboard_div {
    width: 100%;
  }
}

@media screen and (max-width: 425px) {
  .lazySelect {
    width: 100%;
    margin-bottom: 10px;
  }

  .banner-text {
    line-height: 24px;
    margin-bottom: 15px;
    font-size: 12px;
  }
}
