.qr-code-div {
  height: auto;
  margin: 0 auto;
  width: 100%;
  max-width: 500px;
}

// details
.title {
  color: black;
  font-weight: 700;
}
// details
// product-listing
.product-status {
  padding: 1px 12px 4px 12px;
  border-radius: 12px 12px 12px 12px;
  color: white;
  text-transform: capitalize;
}
.product-status-draft {
  background-color: #007bff;
}
td span {
  font-size: small;
}
// product-listing

//privacy=policy
.defination ul {
  margin: 10px 0 0 70px !important;
}

@media screen and (max-width: 1440px) {
  .qr-code-div {
    width: 100%;
    max-width: 400px;
  }
}

.select-date-div {
  display: flex;
}
@media screen and (max-width: 830px) {
  .select-date-div {
    display: flex;
    flex-direction: column;
  }
}
