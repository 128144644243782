.dropdown-div {
  width: 50%;
}
.receiving-location-div {
  width: 50%;
}
.receving-qr-code-div {
  height: auto;
  margin: 0 auto;
  width: 100%;
  max-width: 400px;
}

.receiving-div {
  border-radius: 8px;
  background: white;
  padding: 0;
}
.receiving-div p {
  font-size: 20px;
  font-weight: 700;
}

.product-img {
  max-width: 50px;
  max-height: 50px;
  margin-right: 10px;
  width: 100%;
}

// Receving inventory
.receive-inventory-header {
  margin-left: 10px;
}
.receive-inventory-header-title-title-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 8px;
  padding-block: 2px;
}
.receive-inventory-header-title {
  display: inline;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.25rem;
}
.receive-inventory-header-sub-title-wrapper {
  margin-top: 2px;
  color: rgba(97, 97, 97, 1);
}
.receive-inventory-header-sub-title {
  font-size: 12px;
  font-weight: 450;
  letter-spacing: 0;
  line-height: 16px;
  margin: 0;
  text-align: inherit;
}
.table-td-accept {
}
.table-input-text-field {
  // width: 90%;
  border-radius: 10px;
}
// Receving inventory

@media screen and (max-width: 1440px) {
  .receiving-location-div {
    width: 100%;
  }
}

@media screen and (max-width: 425px) {
  .dropdown-div {
    width: 100%;
  }
}
